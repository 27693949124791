import React from 'react';
import { UserProvider } from './context';
import Routes from './Routes';
import { NotificationsWrapper } from './components'; 

function App() {
  return (
    <>
    <NotificationsWrapper />
    <UserProvider>
        <Routes />
    </UserProvider>
    </>
  );
}

export default App;
