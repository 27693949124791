import PropTypes from 'prop-types';
import React from 'react';

export const Input = ({ label, ...props }) => {
  return (
    <div className="form-group row">
      <label className="col-sm-3 col-form-label">{label}</label>
      <div className="col-sm-9">
        <input type="text" className="form-control" {...props} />
      </div>
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
};
