import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { UserContext } from '../../context';

export const AppCard = ({ app, onClickDelete, onClickPermissions }) => {
  const { permissions } = useContext(UserContext);
  const { url } = useRouteMatch();

  return (
    <div className="col mb-3">
      <div className="card h-100 border border-dark">
        <div className="card-header">{app.application.code}</div>
        <div className="card-body">
          <p>{app.fullName}</p>
        </div>
        <div className="card-footer bg-transparent d-flex justify-content-between align-items-center">
          <Link className="card-link" to={`${url}/${app.application.code}`}>
            Ver
          </Link>
          {permissions.canCreateApplication && (
            <button className="btn btn-link" onClick={onClickDelete}>
              Eliminar
            </button>
          )}
          {permissions.canEditApplication && (
            <button className="btn btn-link" onClick={onClickPermissions}>
              Permisos
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

AppCard.propTypes = {
  app: PropTypes.object.isRequired,
  onClickDelete: PropTypes.func,
  onClickPermissions: PropTypes.func,
};
