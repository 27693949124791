import React, { useEffect, useState } from 'react';
import { notificationService } from '../../../services';
import { Notification } from './Notification';
import './NotificationsWrapper.scss';

const NotificationsWrapper = () => {
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    notificationService.subscribe((notification) => {
      setNotifications((actual) => [...actual, notification]);
    });
  }, []);

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      className="notification-wrapper"
    >
      {notifications.map((notification, key) => (
        <Notification key={key} {...notification} />
      ))}
    </div>
  );
};
export { NotificationsWrapper };
