import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { AppDetail, Apps } from '../Apps';
import { Entities } from '../Entities';


export const Routes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/applications/:id`} component={AppDetail} />
      <Route path={`${path}/applications`} component={Apps} />
      <Route path={`${path}/entities`} component={Entities} />
    </Switch>
  );
};
