import PropTypes from 'prop-types';
import React from 'react';

export const Cards = ({ cols = 1, children }) => (
  <div className={`row row-cols-1 row-cols-md-${cols}`}>{children}</div>
);

Cards.propTypes = {
  cols: PropTypes.number,
  children: PropTypes.any,
};
