import React, { useEffect, useState } from 'react';
import { AppCard } from '../../components/Apps';
import { Cards, ModalUI, TagInput } from '../../components/shared';
import { appService, entityService, notificationService } from '../../services';

export const Apps = () => {
  const [loading, setLoading] = useState(true);
  const [apps, setApps] = useState([]);
  const [activeApp, setActiveApp] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [tags, setTags] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    let isAlive = true;

    const getInfoApps = async () => {
      try {
        const roles = await entityService.getRoles();
        const { data: apps } = await appService.list();
  
        setApps(apps);
        setRoles(roles.map((role) => role.permissions));
      } catch (error) {
        notificationService.showError({
          title: 'Error',
          message: `Hubo un error en el sistema: ${error.err}`,
        });
      } finally {
        setLoading(false);
      }
    };

    if (isAlive) {
      getInfoApps();
    }
    return () => (isAlive = false);
  }, []);

  // App list
  const loadAppList = () => {
    appService.list().then((res) => setApps(res.data));
  };

  // Modal
  const showModalHandler = (app, modalType) => {
    setActiveApp(app);
    setModalType(modalType);
    if (modalType === 'update') {
      setTags(app.permissions);
    } else {
      setTags([]);
    }
  };
  const closeModalHandler = () => {
    setActiveApp(null);
    setModalType(null);
    setTags([]);
  };

  // Delete App
  const deleteAppHandler = () => {
    appService
      .remove(activeApp.application.code)
      .then((res) => {
        closeModalHandler();
        notificationService.showSuccess({
          title: '',
          message: 'Aplicación eliminada',
        });
      })
      .then(loadAppList);
  };

  // Permissions
  const addTagHandler = (tag) => {
    setTags([...tags, tag]);
  };

  const deleteTagHandler = (i) => {
    setTags(tags.filter((_, index) => index !== i));
  };

  const updatePermissionsHandler = () => {
    entityService
      .updatePermissions(activeApp._id, {
        permissions: tags,
      })
      .then((res) => {
        closeModalHandler();
        notificationService.showSuccess({
          title: 'Actualización de permisos',
          message: 'Los permisos han sido cambiados satisfactoriamente',
        });
      })
      .then(loadAppList)
      .catch(() => {
        notificationService.showWarning({
          title: 'Actualización de permisos',
          message: 'Ups, algo inesperado ha sucedido',
        });
      });
  };

  const modalButtonsEdit = [
    {
      label: 'Cancelar',
      variant: 'btn btn-secondary',
      onClick: closeModalHandler,
    },
    {
      label: 'Guardar',
      type: 'submit',
      onClick: () => updatePermissionsHandler(),
    },
  ];

  const modalButtonsRemove = [
    {
      label: 'Cancelar',
      variant: 'btn btn-secondary',
      onClick: closeModalHandler,
    },
    {
      label: 'Guardar',
      type: 'submit',
      onClick: () => deleteAppHandler(),
    },
  ];

  if (loading) return null;

  return (
    <div className="container">
      <h2>Aplicaciones</h2>
      <p>Lista de aplicaciones con acceso a Jauth</p>
      <Cards cols={3}>
        {apps.map((app) => (
          <AppCard
            key={app._id}
            app={app}
            onClickDelete={() => showModalHandler(app, 'delete')}
            onClickPermissions={() => showModalHandler(app, 'update')}
          />
        ))}
      </Cards>

      {/* Modals */}
      {activeApp && (
        <>
          {modalType === 'delete' && (
            <ModalUI
              title={`Eliminar aplicación ${activeApp.application.code}`}
              onClose={closeModalHandler}
              buttons={modalButtonsRemove}
            >
              <p>Eliminar una aplicación no puede deshacerse</p>
            </ModalUI>
          )}
          {modalType === 'update' && (
            <ModalUI
              title={`Permisos para ${activeApp.fullName}`}
              onClose={closeModalHandler}
              buttons={modalButtonsEdit}
            >
              <small>
                Para agregar un permiso escríbalo y luego presione{' '}
                <code>Enter</code>
              </small>
              <TagInput
                tags={tags}
                onAdd={addTagHandler}
                onDelete={deleteTagHandler}
                placeholder="Permiso"
                list={roles}
              />
            </ModalUI>
          )}
        </>
      )}
    </div>
  );
};
