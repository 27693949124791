import { roles } from './roles.enum';

export const routesPermissionsMap = [
  {
    permissionName: roles.jauthAdministrator,
    title: 'Entidades',
    url: 'entities',
  },
  {
    permissionName: roles.jauthAdministrator,
    title: 'Aplicaciones',
    url: 'applications',
  },
];
