import PropTypes from 'prop-types';
import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { Error } from './components/Error';
import { Admin, Callback, Login } from './containers';
import { UserConsumer } from './context';

const Routes = () => {
  return (
    <Router>
      <UserConsumer>
        {({ user }) => (
          <Switch>
            <Route exact path="/error/:code" component={Error} />
            <UnauthenticatedRoute
              restricted={false}
              exact
              path={['/', '/login']}
              component={Login}
              user={user}
            />
            <UnauthenticatedRoute
              restricted={false}
              path="/callback"
              component={Callback}
              user={user}
            />
            <PrivateRoute path="/admin" component={Admin} user={user} />
          </Switch>
        )}
      </UserConsumer>
    </Router>
  );
};

const PrivateRoute = ({ component: Component, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

const UnauthenticatedRoute = ({
  component: Component,
  restricted,
  user,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !user && !restricted ? (
          <Component {...props} />
        ) : (
          <Redirect to="/admin/entities" />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  user: PropTypes.any,
};

UnauthenticatedRoute.propTypes = {
  component: PropTypes.any,
  restricted: PropTypes.bool,
  user: PropTypes.any,
};

export default Routes;
