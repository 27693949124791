// @ts-nocheck
import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import './ModalUI.scss';
import { Button } from '../Button';

const modalRoot = document.getElementById('modal-root');

export const ModalUI = ({
  title,
  onClose,
  children,
  buttons,
  position,
  className,
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    modalRef.current.position = position;
    modalRef.current.addEventListener('closeModal', onClose);
    modalRef.current.addEventListener('openModal', () => {});
  });

  useEffect(() => {
    modalRef.current.openModal();
  });

  const close = () => {
    modalRef.current.closeModal();
  };

  const modal = (
    <st-core-modal ref={modalRef}>
      <div className={className}>
        <div className="modal-header">
          <span className="modal-title">{title}</span>
          <button
            type="button"
            className="close-button"
            onClick={close}
            data-automation="skill-add-close"
          >
            <i className="icon-close-1"></i>
          </button>
        </div>
        <div className="modal-body">{children}</div>
        <div className="d-flex justify-content-center bg-light p-4 spaced">
          {buttons.map(({ label, ...button }, i) => (
            <Button key={i} {...button}>
              {label}
            </Button>
          ))}
        </div>
      </div>
    </st-core-modal>
  );

  return ReactDOM.createPortal(modal, modalRoot);
};

ModalUI.propTypes = {
  position: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  buttons: PropTypes.array.isRequired,
};
