import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Notification = ({ title, message, type }) => {
  const toastRef = useRef(null);

  useLayoutEffect(() => {
    toastRef.current.type = type;
    toastRef.current.header = title;
    toastRef.current.message = message;
  });

  return <st-core-toast ref={toastRef} />;
};

Notification.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
};

export { Notification };
