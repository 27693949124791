import PropTypes from 'prop-types';
import React from 'react';
import './Tag.scss';

export const Tag = ({ label, onClickDelete }) => (
  <div className="tag">
    <div className="tag-content">
      <div className="tag-content__text">{label}</div>
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={onClickDelete}
      >
        <i className="icon-close-1"></i>
      </button>
    </div>
  </div>
);

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};
