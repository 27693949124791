import React, { useEffect, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { queryURL, useQuery } from '../../../utils/params';
import { useHistory } from 'react-router-dom';

const PaginatorUI = ({ totalItems }) => {
  const history = useHistory();
  const paginatorRef = useRef(null);
  const { page, pageSize, ...rest } = useQuery();

  const handleChangePage = ({ detail }) => {
    const query = queryURL({ page: detail, pageSize, ...rest });
    history.push(query);
  };

  const handleChangePageSize = ({ detail }) => {
    const query = queryURL({ page: 1, pageSize: detail, ...rest });
    history.push(query);
  };

  useLayoutEffect(() => {
    paginatorRef.current.page = page;
    paginatorRef.current.pageSize = pageSize;
    paginatorRef.current.totalItems = totalItems;
  }, [page, pageSize, totalItems]);

  useEffect(() => {
    paginatorRef.current.addEventListener(
      'changePage',
      handleChangePage,
      false,
    );
    paginatorRef.current.addEventListener(
      'changePageSize',
      handleChangePageSize,
      false,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, ['']);

  return <st-core-paginator ref={paginatorRef} />;
};

PaginatorUI.propTypes = {
  totalItems: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
};

export { PaginatorUI };
