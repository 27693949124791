/* eslint-disable no-undef */
const localEnv = {
  API_BASE_URL: 'http://localhost:3307/api/admin/v1',
  APPLICATION_CODE: 'jauth-admin-local',
  JAUTH_URL: 'http://localhost:3301',
};
const devEnv = {
  API_BASE_URL: 'https://jauth.psl.xyz:7443/api/admin/v1',
  APPLICATION_CODE: 'jauth-admin-dev',
  JAUTH_URL: 'https://jauth.psl.xyz:7443',
};
const qaEnv = {
  API_BASE_URL: 'https://jauth.psl.xyz:9443/api/admin/v1',
  APPLICATION_CODE: 'jauth-admin-qa',
  JAUTH_URL: 'https://jauth.psl.xyz:9443',
};
const prodEnv = {
  API_BASE_URL: 'https://jauth.psl.xyz/api/admin/v1',
  APPLICATION_CODE: 'jauth-admin',
  JAUTH_URL: 'https://jauth.psl.xyz',
};

export const environment = (() => {
  switch (process.env.REACT_APP_ENV) {
    case 'prod':
      return prodEnv;
    case 'dev':
      return devEnv;
    case 'qa':
      return qaEnv;
    default:
      return localEnv;
  }
})();
