import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const TableUI = ({
  data,
  thead,
  classTableName = '',
  isCustomBody = false,
  children,
}) => {
  const tableRef = useRef(null);

  useLayoutEffect(() => {
    tableRef.current.data = data;
    tableRef.current.thead = thead;
    tableRef.current.isCustomBody = isCustomBody;
    tableRef.current.classTableName = classTableName;
  });

  // @ts-ignore
  return <st-core-table ref={tableRef}>{children}</st-core-table>;
};

TableUI.propTypes = {
  data: PropTypes.array.isRequired,
  thead: PropTypes.array.isRequired,
  classTableName: PropTypes.string,
  isCustomBody: PropTypes.bool,
  children: PropTypes.any,
};

export { TableUI };
