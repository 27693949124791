import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { storageService } from '../../services';
import { GetUser } from './GetUser';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const Callback = ({ location, history }) => {
  let component;
  const callbackToken = useQuery().get('token');

  if (callbackToken) {
    storageService.setToken(callbackToken);
    component = <GetUser />;
  } else {
    component = <Redirect to="/" />;
  }

  return component;
};
