import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';

import { queryURL, useQuery } from '../../utils/params';

import { TableUI, PaginatorUI } from '../../components/shared';
import { EditEntity, EntityRow } from './components';

import { entityService } from '../../services';

const thead = [
  { key: 'fullName', name: 'Nombre', sortKey: 'type.name' },
  { key: 'pslEmail', name: 'Email', sortKey: 'level.name' },
  { key: 'actions', name: 'Acciones', isComponent: true },
];

export const Entities = () => {
  const [entities, setEntities] = useState([]);
  const [total, setTotal] = useState(0);
  const [roles, setRoles] = useState([]);
  const [activeEntity, setActiveEntity] = useState(null);
  const [reload, setReload] = useState(false);

  const { q, role, ...urlParams } = useQuery();
  const history = useHistory();

  useEffect(() => {
    entityService
      .list({ q, role, ...urlParams })
        .then(({ data, total }) => {
          setEntities(data);
          setTotal(total);
        })
        .catch(console.warn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location, reload]);

  useEffect(() => {
    let isLoad = true;
    entityService
      .getRoles()
      .then((value) => value.map((role) => role.permissions))
      .then(isLoad && setRoles)
      .catch(console.warn);
    return () => (isLoad = true);
  }, []);

  const handleChangeRole = ({ target }) => {
    const query = queryURL({ page: 1, role: target.value, q, ...urlParams });
    history.push(query);
  };

  const handleChangeQ = (e) => search(e.target.value);

  const search = _.debounce((value) => {
    const query = queryURL({ page: 1, role, q: value, ...urlParams });
    history.push(query);
  }, 500);

  const showPermissionsModal = (entity) => {
    setActiveEntity(entity);
  };
  const closeModal = (val) => {
    if (val) {
      setReload((state) => !state);
    }
    setActiveEntity(null);
  };

  return (
    <>
      <div className="top-bar-container row mb-5">
        <input
          className="form-control col"
          onChange={handleChangeQ}
          defaultValue={q}
        />
        <select
          placeholder="role"
          className="form-control ml-5 col"
          onChange={handleChangeRole}
          defaultValue={role}
        >
          <option></option>
          {roles.map((role) => (
            <option key={role} value={role}>
              {' '}
              {role}{' '}
            </option>
          ))}
        </select>
      </div>
      <div className="scrollable-x">
        <TableUI thead={thead} data={entities} isCustomBody={true}>
          <tbody slot="body">
            {entities.map(
              ({
                _id,
                displayName,
                pslEmail,
                fullName,
                email,
                permissions,
              }) => (
                <EntityRow
                  key={_id}
                  name={displayName ? displayName : fullName}
                  email={pslEmail ? pslEmail : email}
                  onClickPermissions={() =>
                    showPermissionsModal({ _id, permissions, fullName })
                  }
                />
              ),
            )}
          </tbody>
          <div slot="footer">
            {total > 0 && <PaginatorUI totalItems={total} />}
          </div>
        </TableUI>
      </div>
      {activeEntity && (
        <EditEntity activeEntity={activeEntity} closeModal={closeModal} />
      )}
    </>
  );
};
