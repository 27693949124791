import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../../context';

export const GetUser = () => {
  const [loading, setLoading] = useState(true);
  const { user, login } = useContext(UserContext);

  /**
   * Get token and call the user API
   */
  useEffect(() => {
    let isAlive = true;
    if (!user) {
      login().then(() => (isAlive ? setLoading(false) : null));
    }
    return () => (isAlive = false);
  }, [login, user]);

  return loading ? null : <Redirect to={{ pathname: '/admin/entities' }} />;
};
