import { useLocation } from 'react-router-dom';

export const queryURL = (params) => {
  const query = Object.keys(params)
    .filter((key) => params[key])
    .map((key) => [key, params[key]].join('='))
    .join('&');
  return `?${query}`;
};

export const useQuery = () => {
  const query = new URLSearchParams(useLocation().search);
  return {
    page: parseInt(query.get('page')) || 1,
    pageSize: parseInt(query.get('pageSize')) || 10,
    q: query.get('q') || '',
    role: query.get('role') || '',
    sortBy: query.get('sortBy') || '',
    order: query.get('order') || '',
    type: query.get('type') || '',
    filterByType: query.get('filterByType') || '',
    filterByArea: query.get('filterByArea') || '',
    filterByLevel: query.get('filterByLevel') || '',
  };
};
