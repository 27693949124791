import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { UserContext } from '../../../../context';

export const EntityRow = ({ name, email, onClickPermissions }) => {
  const { permissions } = useContext(UserContext);
  return (
    <tr>
      <td>
        {' '}
        <span className="text-cell">{name}</span>
      </td>
      <td>
        <span className="text-cell">{email} </span>
      </td>
      <td className="text-capitalize">
        <span className="text-cell">
          {permissions.canEditPermissions && (
            <button className="btn btn-link" onClick={onClickPermissions}>
              Permisos
            </button>
          )}
        </span>
      </td>
    </tr>
  );
};

EntityRow.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  onClickPermissions: PropTypes.func,
};
