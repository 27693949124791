import _ from 'lodash';
import { roles } from './roles.enum';
import { routesPermissionsMap } from './routesPermissionsMap';

const getPermissions = (arr) => {
  const found = arr.includes(roles.jauthAdministrator);
  return {
    canCreateApplication: found,
    canViewApplication: found,
    canEditApplication: found,
    canEditPermissions: found,
  };
};

const getLinksForPermissions = (userPermissions) => {
  const permissionsMap = [];

  for (let permission of userPermissions) {
    const actualPermission = _.filter(routesPermissionsMap, [
      'permissionName',
      permission,
    ]);
    if (actualPermission.length) {
      permissionsMap.push(...actualPermission);
    }
  }

  return permissionsMap;
};

export const role = {
  getPermissions,
  getLinksForPermissions,
};
