import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';
import { apiService, storageService } from '../services';
import { environment, role } from '../utils';

const currentUser = storageService.getUser();
const currentPermissions = storageService.getPermissions();

export const UserContext = createContext(undefined);

export const UserConsumer = UserContext.Consumer;

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(currentUser);
  const [permissions, setPermissions] = useState(currentPermissions);

  const login = async (setUser) => {
    try {
      const user = await apiService.fetchURL(`${environment.API_BASE_URL}/me`);
      setUser(user);
      storageService.setUser(user);
      const permissions = role.getPermissions(user.permissions);
      setPermissions(permissions);
      storageService.setPermissions(permissions);
    } catch (error) {
      setUser(undefined);
      setPermissions({});
      storageService.deleteToken();
    }
  };

  const logout = () => {
    storageService.deleteUser();
    storageService.deleteToken();
    storageService.deletePermissions();
    window.location.reload();
  };

  return (
    <UserContext.Provider
      value={{
        user,
        permissions,
        login: () => login(setUser),
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
UserProvider.propTypes = {
  children: PropTypes.any,
};
