import axios from '../interceptors/Interceptor'; // importing axios from customAxios
import { environment } from '../utils';
import { apiService } from './apiService';
import { errorService } from './errorService';

function request(url, method, data) {
  return apiService.fetchURL(`${environment.API_BASE_URL}${url}`, method, data);
}

const list = ({page, pageSize, q, role}) => {
  let url = '/employees';
  if (page || pageSize || q) {
    url += '?';
  }
  if (page) {
    url += `&page=${page}`;
  }
  if (pageSize) {
    url += `&pageSize=${pageSize}`;
  }
  if (q) {
    url += `&q=${q}`;
  }
  if (role) {
    url += `&role=${role}`;
  }
  return axios.get(`${environment.API_BASE_URL}${url}`)
  .then((res) => res.data)
  .catch((err) => errorService.handle(err));
};

const getRoles = () =>
   request('/roles');

const updatePermissions = (id, data) => {
  return request(`/employee/${id}/permissions`, 'patch', data);
};

export const entityService = {
  list,
  getRoles,
  updatePermissions,
};
