import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { UserContext } from '../../context';
import { role } from '../../utils';

import logo from '../../assets/img/Logos-Jauht-PRFT-15.svg';

import  './header.scss';

export const Header = () => {
  const { url } = useRouteMatch();
  const { user, logout } = useContext(UserContext);
  const [permissionsLinks, setPermissionsLinks] = useState([]);

  useEffect(() => {
    setPermissionsLinks(role.getLinksForPermissions(user.permissions));
  }, [setPermissionsLinks, user.permissions]);

  return (
    <>
      <header className="d-flex align-items-center bg-dark text-white header">
        <img src={logo} alt="Logo" className="ml-3" height="50" />
        <div className="text-left ml-auto mr-4">
          <b>Bienvenid@</b>
          <div className="font-weight-bold">{user.name}</div>
          <div className="small">{user.pslEmail}</div>
        </div>
        <div className="d-flex header-logout" onClick={logout}>
          <i className="icon icon-close-1 m-auto" />
        </div>
      </header>
      <nav className="main-content">
        <div className="menu">
          <ul className="nav menu-nav">
            {permissionsLinks
              ? permissionsLinks.map((item, index) => (
                  <li key={index} className="menu-nav--item">
                    <NavLink
                      to={`${url}/${item.url}`}
                      className="nav-link"
                      activeClassName="active"
                    >
                      {item.title}
                    </NavLink>
                  </li>
                ))
              : null}
          </ul>
        </div>
      </nav>
    </>
  );
};
