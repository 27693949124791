import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ModalUI, TagInput } from '../../../../components/shared';
import { entityService, notificationService } from '../../../../services';

export const EditEntity = ({ closeModal, activeEntity }) => {
  const [roles, setRoles] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setTags(activeEntity.permissions);
    let isLoad = true;
    entityService
      .getRoles()
      .then((value) => value.map((role) => role.permissions))
      .then(isLoad && setRoles)
      .catch(console.warn);
    return () => (isLoad = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Permissions
  const addTagHandler = (tag) => {
    setTags([...tags, tag]);
  };
  const deleteTagHandler = (i) => {
    setTags(tags.filter((_, index) => index !== i));
  };

  const modalButtons = [
    { label: 'Cancelar', variant: 'btn btn-secondary', onClick: closeModal },
    {
      label: 'Guardar',
      type: 'submit',
      onClick: () => updatePermissionsHandler(),
    },
  ];
  const updatePermissionsHandler = () => {
    entityService
      .updatePermissions(activeEntity._id, {
        permissions: tags,
      })
      .then(() => {
        closeModal(true);
        notificationService.showSuccess({
          title: 'Actualización de permisos',
          message: 'Los permisos han sido cambiados satisfactoriamente',
        });
      })
      .catch(() => {
        notificationService.showWarning({
          title: 'Actualización de permisos',
          message: 'Ups, algo inesperado ha sucedido',
        });
      });
  };

  return (
    <ModalUI
      title={`Permisos para ${activeEntity.fullName}`}
      onClose={closeModal}
      buttons={modalButtons}
    >
      <small>
        Para agregar un permiso escríbalo y luego presione <code>Enter</code>
      </small>
      <TagInput
        tags={tags}
        onAdd={addTagHandler}
        onDelete={deleteTagHandler}
        placeholder="Permiso"
        list={roles}
      />
    </ModalUI>
  );
};

EditEntity.propTypes = {
  activeEntity: PropTypes.object.isRequired,
  closeModal: PropTypes.func,
};
