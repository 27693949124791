import Cookies from 'js-cookie';

const setCookie = (name, value, days = 1) => {
  Cookies.set(name, value);
};

const getCookie = (cookieName) => {
  return Cookies.get(cookieName);
};

const deleteCookie = (name) => {
  Cookies.remove(name);
};

export const cookieService = {
  deleteCookie,
  getCookie,
  setCookie,
};
