import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Tag } from '../Tag';
import './TagInput.scss';

export const TagInput = ({ tags, onAdd, onDelete, placeholder, list = [] }) => {
  const [userInput, setUserInput] = useState('');
  const [filter, setFilter] = useState([]);

  const keyPressHandler = (e) => {
    if (e.key === 'Enter' && userInput.trim() !== '') {
      onAdd(userInput.trim());
      setUserInput('');
    }
  };

  const handleChange = (e) => {
    const search = e.target.value;
    if (search.length > 2) {
      setFilter(
        list.filter((item) =>
          item ? item.toLowerCase().includes(search.toLowerCase()) : '',
        ),
      );
    } else {
      setFilter([]);
    }
  };

  const handledSelectItem = (element) => {
    onAdd(element);
    setUserInput('');
    setFilter([]);
  };

  return (
    <div className="taginput">
      <div className="taginput-container">
        {tags.map((tag, i) => (
          <Tag key={tag + i} label={tag} onClickDelete={() => onDelete(i)} />
        ))}
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyPress={keyPressHandler}
          placeholder={placeholder || 'Agregar tag'}
          className="border px-2 rounded form-control"
          onInput={handleChange}
        />
        {filter.length > 0 && (
          <datalist
            id="inputListOptions"
            className="list-group"
            part="auto-complete"
          >
            {filter.map((element) => (
              <option
                key={element}
                className="list-group__item"
                onClick={() => handledSelectItem(element)}
              >
                {element}
              </option>
            ))}
          </datalist>
        )}
      </div>
    </div>
  );
};

TagInput.propTypes = {
  list: PropTypes.array,
  tags: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};
