import { cookieService } from '../utils';

const setUser = (user) => {
  cookieService.setCookie('user', JSON.stringify(user));
};

const getUser = () => {
  try {
    const user = cookieService.getCookie('user');
    return user ? JSON.parse(user) : undefined;
  } catch (error) {
    return undefined;
  }
};

const deleteUser = () => {
  cookieService.deleteCookie('user');
};

const setToken = (token) => {
  cookieService.setCookie('token', token);
};

const getToken = () => {
  return cookieService.getCookie('token');
};

const deleteToken = () => {
  cookieService.deleteCookie('token');
};

const setPermissions = (data) => {
  cookieService.setCookie('permissions', JSON.stringify(data));
};

const getPermissions = () => {
  if (cookieService.getCookie('permissions')) {
    return JSON.parse(cookieService.getCookie('permissions'));
  } else return null;
};

const deletePermissions = () => {
  cookieService.deleteCookie('permissions');
};

const storageService = {
  setUser,
  getUser,
  deleteUser,
  setToken,
  getToken,
  deleteToken,
  setPermissions,
  getPermissions,
  deletePermissions,
};

export { storageService };
