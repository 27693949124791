import React from 'react';
import Logo from '../../assets/img/Logos-Jauht-PRFT-15.svg';
import { environment } from '../../utils';
import './Login.scss';

export const Login = () => {
  const { JAUTH_URL: url, APPLICATION_CODE: code } = environment;
  const linkJAuth = `${url}/api/v2/authentication/login?app-code=${code}`;

  return (
  <div className="login">
    <div className="login__content">    
      <div className="login__box-form">
        <div className="login__box-form-container">
          <div>
            <img alt="Lighthouse Logo" src={Logo} className="img-fluid img"></img>
          </div>
          <div className="btn-wrapper">
            <a className="btn btn-primary mt-5" href={linkJAuth}>Iniciar sesión con Office 365</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};
