import _ from 'lodash';

const handle = (error) => {
  const status = _.get(error, 'response.status');
  if (status === 401) {
    window.location.replace('/error/401');
  }

  if (status === 403) {
    window.location.replace('/error/403');
  }

  throw error;
};

export const errorService = {
  handle,
};
