import moment from 'moment';
import 'moment/locale/es';
import React, { useContext, useEffect, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { Input } from '../../components/shared';
import { UserContext } from '../../context';
import { appService, notificationService } from '../../services';

moment.locale('es');

export const AppDetail = () => {
  const { id } = useParams();
  const { permissions } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [app, setApp] = useState({
    code: '',
    name: '',
    redirectUrl: '',
    secret: '',
    created: '',
    updated: '',
  });

  useEffect(() => {
    let isAlive = true;
    appService.get(id).then((res) => {
      if (isAlive) {
        fillData(res);
        setLoading(false);
      }
    });
    return () => (isAlive = false);
  }, [id]);

  const onChange = (e) => {
    setApp({
      ...app,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    appService
      .edit(id, {
        name: app.name,
        secret: app.secret,
        secretKey: app.secret,
        redirectUrl: app.redirectUrl,
      })
      .then((res) => {
        fillData(res);
        notificationService.showSuccess({
          title: 'Aplicación',
          message: 'Cambios guardados',
        });
      });
  };

  const fillData = (app) => {
    setApp({
      code: app.application.code,
      name: app.fullName,
      redirectUrl: app.application.redirectUrl,
      secret: app.application.secret,
      created: app.createdAt,
      updated: app.updatedAt,
    });
  };

  if (loading) {
    return null;
  } else if (!permissions.canViewApplication) {
    return <Redirect to="/admin/applications" />;
  }

  let isValid =
    app.name.length > 0 && app.secret.length > 0 && app.redirectUrl.length > 0;

  return (
    <div className="container">
      <div className="card border border-dark">
        <div className="card-header">
          <h2 className="text-capitalize">{id} application</h2>
        </div>
        <div className="card-body">
          <form onSubmit={onSubmit}>
            <Input
              label="Código"
              name="code"
              value={app.code}
              onChange={onChange}
              disabled
            />
            <Input
              label="Nombre"
              name="name"
              value={app.name}
              onChange={onChange}
              disabled={!permissions.canCreateApplication}
              required
            />
            <Input
              label="Url de redirección"
              name="redirectUrl"
              value={app.redirectUrl}
              onChange={onChange}
              disabled={!permissions.canCreateApplication}
              required
            />
            <Input
              label="Secreto"
              name="secret"
              value={app.secret}
              onChange={onChange}
              disabled={!permissions.canCreateApplication}
              required
            />
            <Input
              label="Creada el"
              name="created"
              value={moment(app.created).format('LLLL')}
              disabled
            />
            <Input
              label="Ultima actualización"
              name="updated"
              value={moment(app.updated).format('LLLL')}
              disabled
            />
            <div className="d-flex justify-content-end">
              {permissions.canCreateApplication && (
                <button className="btn btn-dark" disabled={!isValid}>
                  Guardar
                </button>
              )}
              <Link to="/admin/applications" className="btn btn-light ml-3">
                Cancelar
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
