let listener = null;
const storageKey = 'notifications';
const subscribe = (cb) => {
  listener = cb;
  for (const notification of getLocalNotifications()) {
    listener(notification);
  }
  clearLocalNotifications();
};
const clearLocalNotifications = () => {
  localStorage.removeItem(storageKey);
};
const getLocalNotifications = () => {
  const notifications = JSON.parse(localStorage.getItem(storageKey) || '[]');
  if (!notifications.length) {
    clearLocalNotifications();
  }
  return notifications;
};
const addNotificationToStorage = (notification) => {
  const notifications = getLocalNotifications();
  localStorage.setItem(storageKey, JSON.stringify([...(notifications || []), notification]));
};
const showWarning = ({ title, message }) => {
  if (listener) {
    listener({ message, title, type: 'warning' });
  } else {
    addNotificationToStorage({ message, title, type: 'warning' });
  }
};
const showSuccess = ({ title, message }) => {
  if (listener) {
    listener({ message, title, type: 'success' });
  } else {
    addNotificationToStorage({ message, title, type: 'warning' });
  }
};
export const notificationService = {
  subscribe,
  showWarning,
  showSuccess,
};
