import axios from 'axios';
import { cookieService } from '../utils';
import { errorService } from './errorService';

/** @todo Change get token for a context instead of cookie, due performance reasons */
/** @todo Implement abstraction for jauth calls */

const fetchURL = (url, method, data) => {
  return axios({
    url,
    headers: {
      Authorization: `Bearer ${cookieService.getCookie('token')}`,
      'Content-Type': 'application/json',
    },
    method,
    data,
  })
    .then((res) => res.data)
    .catch((err) => errorService.handle(err));
};

const fetchURLWithOutToken = (url, method, data) => {
  return axios({
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    method,
    data,
  })
    .then((res) => res.data)
    .catch((err) => errorService.handle(err));
};

export const apiService = { fetchURL, fetchURLWithOutToken };
