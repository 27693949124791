import { environment } from '../utils';
import { apiService } from './apiService';

function request(url, method, data) {
  return apiService.fetchURL(`${environment.API_BASE_URL}${url}`, method, data);
}

const list = () => {
  return request('/applications');
};

const get = (id) => {
  return request(`/applications/${id}`);
};

const create = (id, data) => {
  return request('/applications', 'post', data);
};

const edit = (id, data) => {
  return request(`/applications/${id}`, 'patch', data);
};

const remove = (id) => {
  return request(`/applications/${id}`, 'delete');
};

export const appService = {
  list,
  get,
  create,
  edit,
  remove,
};
