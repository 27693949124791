import React from 'react';
import { Header } from '../../components';
import { Routes } from './Routes';

export const Admin = () => (
  <>
    <Header />
    <div className="container-fluid bg-light py-4 mb-4">
      <div className="card card-table">
        <Routes />
      </div>
    </div>
  </>
);
